<template>
<div class="titre-block py-1"  v-intersect="defaultIntersect">
  <v-container>
    <div v-if="blc.isAnchor" :id="blc.uid"></div>
    <pi-title
        :big="isBig"
        :medium="isMedium"
        :seo="blc.seo"
    >{{ blc.text }}</pi-title>
  </v-container>

</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import PiTitle from "@/components/atoms/pi-title";
import InVpMixin from "@/components/atoms/InVpMixin";

export default {
  name: "titre-block",
  components: {
    PiTitle,
  },
  mixins:[BlockMixin,InVpMixin],
  computed:{
    /**
     *
     * @return {TitreBlock}
     */
    blc(){
      return this.block
    },
    isBig(){
      return this.blc.cssClass==='h3'
    },
    isMedium(){
      return !this.isBig;
    },

  },
  watch:{
    isIntersecting(){
      if(this.isIntersecting){
        if(this.blc.isAnchor){
          this.$layout.currentAnchor=this.blc.uid;
        }
      }
    }
  }

}
</script>
<style lang="less">
.titre-block{
  .titre{
    color: var(--color-gris-fonce);
    text-transform: uppercase;
    &.text-medium{
      color: var(--color-yellow);
    }

  }
}



</style>
