<template>
<div class="paragraph-block">
  <v-container :id="blc.uid">
    <v-row class="">
      <v-col class="col1 ty-rich-text has-href-underline"
             cols="10"
             md="6"
             lg="5"
             >
        <div class="txt-paragraph" v-html="blc.text"/>
      </v-col>
      <v-col class="col2 ty-rich-text has-href-underline"
             cols="10" offset="1"
             md="6" offset-md="0"
             offset-lg="1"
      >
        <div class="txt-paragraph" v-html="blc.text2"/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "paragraph-block",
  components: {},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {ParagraphBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.paragraph-block{}

</style>