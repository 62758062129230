<template>
  <div :class="{'gradient-blue bg-gradient-blue':!transparent}">
  <slot/>
  </div>
</template>

<script>
export default {
  name: "gradient-blue",
  props:{
    transparent:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="less">
  .gradient-blue{
    color: #fff;
    min-width: var(--line-unit);
    min-height: var(--line-unit);
  }
</style>