<template>
<div class="photo-list-swipe-block">

  <swiper
      v-if="itemsCount>0"
      class="swiper"
      ref="swiper"
      :options="swiperOption">
    <swiper-slide
        v-for="(image,index) of images"
        :key="`slide-${image.uid}-${index}`"

        class="slide">
        <img
            :src="image.image.resize(
                1200,1200,
                'contain',
                'transparent',
                80,
                'webp')"
        />
      <div class="legende txt-annotation has-href-underline"
           v-html="image.text"/>
    </swiper-slide>
    <div class="swiper-button swiper-button-prev"
         :class="{active:!isBeginning}"
         slot="button-prev"
         @click="$refs.swiper.$swiper.slidePrev()"
    />
    <div class="swiper-button swiper-button-next"
         :class="{active:!isEnd}"
         slot="button-next"
         @click="$refs.swiper.$swiper.slideNext()"
    />
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>

</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: "photo-list-swipe-block",
  components: {
    Swiper, SwiperSlide
  },
  mixins:[BlockMixin],
  data() {
    let me=this;
    return {
      isEnd:true,
      isBeginning:true,

      swiperOption: {
        speed:500,
        on: {
          'slideChange':function(){
            me.isBeginning=this.isBeginning;
            me.isEnd=this.isEnd;
          },
          'init': function () {
            me.isBeginning=this.isBeginning;
            me.isEnd=this.isEnd;
          },
        },
        pagination: {
          el: '.swiper-pagination'
        },
        centeredSlides:!$layout.left,
        //centerInsufficientSlides:true,
        disabledClass: 'disabled_swiper_button',
        watchOverflow:true,
        slidesPerView: 'auto',
        spaceBetween: $layout.left?0:30,
        loop:true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        /*
        breakpoints: {
          1900: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 2
          },
          700: {
            slidesPerView: 3,
            spaceBetween: 2
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 2
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 2
          }
        }

         */
      }
    }
  },
  mounted(){
    let me=this;
    this.$nextTick(() => {
      me.initSwiper();
    });
  },
  computed:{
    /**
     *
     * @return {PhotoListSwipeBlock}
     */
    blc(){
      return this.block;
    },
    itemsCount(){
      return this.blc.images.items.length;
    },
    hasSwipper(){
      return this.itemsCount>0
    },
    swiper() {
      return this.$refs.swiper.$swiper;
    },
    images(){
      let r=this.blc.images.items;
      if(r.length>0){
        while (r.length<3){
          r=r.concat(this.blc.images.items);
        }
      }
      return r;
    }
  },
  watch:{
    itemsCount(){
      this.initSwiper();
    },
    gridMode(){
      this.initSwiper();
    }
  },
  methods:{
    initSwiper(){
      let me=this;
      setTimeout(() => {
        if(me.hasSwipper){
          me.swiper.update();
        }
      }, 100)
    }
  },
}
</script>

<style lang="less">
@import "../../../../../node_modules/swiper/css/swiper.min.css";
.photo-list-swipe-block{
  padding-top: 10vw;
  padding-bottom: 10vw;
    .slide{
      height: 60vmin;
      width: 80vmin;
      position: relative;
      img{
        transition: transform 0.25s ease-in, filter 0.75s ease-in-out 0.25s;
        filter: brightness(0.4) contrast(1.5) saturate(0);
        position: absolute;
        width: 100%;
        margin-top: 5%;
        height: 90%;
        object-fit: contain;
        transform: scale(0.8);
      }
      .legende{
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
        opacity:0;
        transition: opacity 1s;
      }
      &.swiper-slide-active{
        img{
          transform: scale(1);
          filter: brightness(1) contrast(1) saturate(1);
        }
        .legende{
          opacity: 1;
        }
      }

    }

  .swiper-button{
    color: var(--color-yellow);
    //pointer-events: none;
    opacity: 0.5;
    &.swiper-button-prev{
      transform-origin: left;
    }
    &.swiper-button-next{
      transform-origin: right;
    }
    transform: scale(0.5);
    @media(min-width: @screen-md){
      transform: scale(0.7);
    }
    cursor: default;
    text-shadow: 1px 1px 4px rgba(0,0,0,.5);
    &.active{
      text-shadow: 1px 1px 4px rgba(0,0,0,.5),1px 1px 1px rgba(0,0,0,.5);
      cursor: pointer;
      opacity: 1;
    }
    display: none !important;
  }
  .swiper-pagination-bullets{
    bottom:-5px;
  }
  .swiper-pagination-bullet{
    //todo bug les bullets ne marchent pas
    background-color: #fff;
    cursor: pointer;
  }


}




</style>