<template>
  <div class="media-text-block"
       :id="blc.uid">
    <v-container>
      <v-row class="content">
        <v-col cols="12"
               md="6"
               lg="5"
        class="pa-md-0"
        >
          <pic-iframe
              v-if="blc.mediaType==='iframe'"
              :block="blc"
              class="media"
          />
          <diaporama
              v-if="blc.mediaType==='photos'"
              :field="blc.photos"
              class="media"
          />
        </v-col>
        <v-col
            cols="10" offset="1"
            md="6" offset-md="0"
            offset-lg="1"
           class=" d-flex flex-column justify-center has-href-underline">
          <div v-html="blc.legende" class="ty-rich-text"/>
          <logo-list
              v-if="blc.logos.items.length"
              class="mt-10"
              :fld="blc.logos"/>

        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import PicIframe from "@/pic/pic-iframe";
import LogoList from "@/pic/logo-list";
import Diaporama from "@/pic/diaporama";

export default {
  name: "media-text-block",
  components: {Diaporama, LogoList, PicIframe},
  mixins:[BlockMixin],
  data(){
    return{ }
  },
  computed:{
    /**
     *
     * @return {MediaTextBlock}
     */
    blc(){
      return this.block;
    }
  },
  watch:{ }
}
</script>

<style lang="less">
.media-text-block{
}
</style>