<template>
<div class="iframe-block">
  <v-container>
        <pic-iframe :block="iframeBlock"/>
    <v-row class="my-3 my-md-10 has-href-underline" justify="space-between">
      <!--légende -->
      <v-col class="ty-annotation py-0"
             v-html="iframeBlock.legende"
      />
      <!--liens -->
      <v-col
          v-if="iframeBlock.links.itemsOk.length"
          cols="12" sm="7"
          class="py-0 d-flex flex-grow-0 justify-sm-end ">
        <div class="ty-title-link text-no-wrap mr-5 mr-lg-10 mt-n3 mt-xl-n5">-></div>
        <div class="ty-link d-flex flex-column text-no-wrap align-start">
          <router-link
              v-for="link of iframeBlock.links.items"
              :key="link.uid"
              :to="link.to"
              :href="link.toOrHref"
              :target="link.target">
            {{link.label}}
          </router-link>
        </div>

      </v-col>

    </v-row>
  </v-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import InVpMixin from "@/components/atoms/InVpMixin";
import PiLine from "@/components/atoms/pi-line";
import ContentContainer from "@/pic/content-container";
import PicIframe from "@/pic/pic-iframe";

export default {
  name: "iframe-block",
  components: {PicIframe},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {IframeBlock}
     */
    iframeBlock(){
      return this.block;
    }
  }
}
</script>

<style lang="less">

.iframe-block{






}


</style>