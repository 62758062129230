<template>
<div class="media-block">
  <container-grid-size>
      <v-row>
        <v-col sm="10" offset-sm="1">
              <media-rounded-container>
                <ee09-picture
                    :image-field="blc.image"
                    lg="1200"
                    xl="1400"
                    compression="80"
                />
              </media-rounded-container>
        </v-col>
      </v-row>
  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import Ee09Picture from "@/components/atoms/ee09-picture";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";


export default {
  name: "media-block",
  components: {MediaRoundedContainer, ContainerGridSize, Ee09Picture},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {MediaBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.media-block{

}
</style>