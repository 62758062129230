<template>
  <div class="pi-line"/>
</template>

<script>
export default {
  name: "pi-line"
}
</script>

<style lang="less">
  .pi-line{
    border-top: 1px solid var(--color-yellow);
    height: 1px;
    width: 100%;
    animation-name: kf-grow;
    animation-duration: 1s;
    animation-delay: 0.5s;
    transform-origin: top left;

    @keyframes kf-grow{
      from{
        transform: scaleX(0);
      }
      to{
        transform: scaleX(1);
      }
    }
  }
</style>