<template>
<div class="metiers-block bg-gradient-gris-fonce big" v-if="blc.isOk">
  <container-grid-size class="py-line-2">
    <div class="mb-line-2" v-if="blc.link.isOk">
      <btn-arrow
          :link-item="blc.link"
          color="#FFF"
      />
    </div>
    <div class="list h3 has-href-underline">
      <template v-for="metier of blc.metiers">
        <router-link :to="metier.href"
           :key="metier.uid"
        >{{metier.name}}</router-link>
        <div :key="'bullet-'+metier.uid" class="bullet"></div>
      </template>
    </div>

  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import BtnArrow from "@/components/atoms/btn-arrow";

export default {
  name: "metiers-block",
  components: {BtnArrow, ContainerGridSize},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {MetiersBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.metiers-block{
  color: #fff;
  .list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    a{
      color: #fff;
      &:after{
        top: 1.2em;
      }
      &:hover{
        color: var(--color-gris-clair);
      }
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      line-height: 1.5;
    }
  }
  .bullet{
    flex-grow: 0;
    flex-shrink: 0;
    width: 0.75vw;
    height: 0.75vw;
    display: block;
    border: 3px solid #fff;
    border-radius: 100%;
    margin: 0 1vw;
    &:last-child{
      display: none;
    }
  }
}
</style>