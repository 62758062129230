<template>
<div class="btn-block py-2 text-center">
  <content-container>
    <v-btn
        min-width="200px"
        v-if="blc.lien.isOk"
        :to="blc.lien.to"
        :href="blc.lien.toOrHref"
        color="var(--color-yellow)" tile outlined>
        {{blc.lien.label}}
    </v-btn>
  </content-container>

</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import TitreLienMixin from "@/Models/blocks/texte/TitreLienMixin";
import ContentContainer from "@/pic/content-container";
import PiTitle from "@/components/atoms/pi-title";

export default {
  name: "btn-block",
  components: {
    PiTitle,
    ContentContainer
  },
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {BtnBlock}
     */
    blc(){
      return this.block
    },
  },

}
</script>
<style lang="less">

</style>
