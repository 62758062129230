<template>
  <div class="form-brochure-block form-iffdec">
      <v-container>
        <v-form v-model="valid">
        <v-row justify="center"  v-if="!success">
          <v-col md="10" lg="6" xl="5">
            <v-row>
              <v-col cols="12">
                <div class="txt-annotation grey--text text-right">* champs obligatoires</div>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Nom *"
                    :rules="required"
                    v-model="fields.nom"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Prénom *"
                    :rules="required"
                    v-model="fields.prenom"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Email *"
                    type="email"
                    :rules="emailRules"
                    v-model="fields.email"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Téléphone *"
                    type="tel"
                    :rules="required"
                    v-model="fields.tel"
                />
              </v-col>
              <v-col cols="12">
              <v-textarea
                  validate-on-blur
                  label="Adresse *"
                  :rules="required"
                  v-model="fields.adresse"
                  rows="1"
                  auto-grow
              />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Ville *"
                    :rules="required"
                    v-model="fields.ville"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    validate-on-blur
                    label="Code postal *"
                    :rules="required"
                    v-model="fields.cp"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    validate-on-blur
                    label="Pays *"
                    :rules="required"
                    v-model="fields.pays"
                />
              </v-col>
            </v-row>


          </v-col>
        </v-row>
          <form-footer
              class="mt-5"
              :success="success"
              :valid="valid"
              :sending="sending"
              :messages="messages"
              :errors="errors"
              @submit="submit"
          />
        </v-form>
      </v-container>

  </div>

</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import FormsMixin from "@/Models/blocks/forms/FormsMixin";
import FormFooter from "@/Models/blocks/forms/form-footer";

export default {
  name: "form-brochure-block",
  components: {FormFooter},
  mixins:[BlockMixin,FormsMixin],
  data(){
    return {
      fields:{
        formType: "brochure",
        email: "",
        nom: "",
        prenom: "",
        tel: "",
        adresse: "",
        ville: "",
        cp: "",
        pays: "",
      }
    }
  }
}
</script>